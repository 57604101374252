import React, { useCallback, useEffect, useState } from "react";
import SearchBar from "containers/components/searchBar/SearchBar";
//
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HelpIcon from "@material-ui/icons/Help";
import Fade from "@material-ui/core/Fade";
//

const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
        const currNode = queue.shift();
        if (currNode.id === targetId) {
            return currNode;
        }
        if (currNode.children) {
            queue.push(...currNode.children);
        }
    }

    return []; // Target node not found
};

const useStyles = makeStyles((theme) => ({
    treeItemLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    selectAllButton: {
        minWidth: "9rem",
    },
    selectVisibleButton: {
        minWidth: "11rem",
    },
}));

export default function TreePicker({
    items,
    searchBarOptions = null,
    selectedItems,
    setSelectedItems,
}) {
    const classes = useStyles();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
    const totalNodeCount = countNodes(items);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItems, setFilteredItems] = useState(items);
    const [lastSelectedId, setLastSelectedId] = useState(null);

    const filterItems = useCallback(
        (query) => {
            const output = searchBarOptions?.onFilter(query);
            return output ? output : [];
        },
        [searchBarOptions]
    );

    useEffect(() => {
        if (searchQuery) {
            setFilteredItems(filterItems(searchQuery));
        } else {
            setFilteredItems(items);
        }
    }, [searchQuery, filterItems, items]);

    // Get total number of nodes to determine 'Select All' availability
    function countNodes(items) {
        let total = 0;

        items.forEach((item) => {
            total += item.children ? countNodes(item.children) : 0;
        });

        return total + items.length;
    }

    // Get all IDs from a node to its children's
    function getAllIds(node, idList = []) {
        idList.push(node.id);
        if (node.children) {
            node.children.forEach((child) => getAllIds(child, idList));
        }
        return idList;
    }

    // Get IDs of all children from specific node
    function getAllChildren(id) {
        return getAllIds(bfsSearch(items, id));
    }

    // Get all parent IDs from specific node
    function getAllParents(id, list = []) {
        const node = bfsSearch(items, id);

        if (!!node.parentId) {
            list.push(node.parentId);

            return getAllParents(node.parentId, list);
        }

        return list;
    }

    function isAllChildrenChecked(node, newSelectedNodes) {
        const allChildren = getAllChildren(node.id);
        const nodeIndex = allChildren.indexOf(node.id);
        allChildren.splice(nodeIndex, 1);

        return allChildren.every((nodeId) =>
            selectedItems.concat(newSelectedNodes).includes(nodeId)
        );
    }

    const handleNodeSelect = (event, nodeId) => {
        if (event) {
            event.stopPropagation();
        }

        const affectedNodes = [];

        if (event?.nativeEvent.shiftKey && lastSelectedId) {
            // find next select on same depth
            const parentIds = getAllParents(nodeId);
            const searchItems =
                parentIds.length > 0 ? bfsSearch(items, parentIds[0]).children : items;
            const selectedIndex = searchItems.findIndex((item) => nodeId === item.id);
            const previousIndex = searchItems.findIndex((item) => item.id === lastSelectedId);

            if (selectedIndex < 0 || previousIndex < 0) {
                return;
            }

            let i = selectedIndex > previousIndex ? previousIndex : selectedIndex;
            const max = selectedIndex > previousIndex ? selectedIndex : previousIndex;

            // mark selected nodes
            for (; i <= max; i++) {
                const children = getAllChildren(searchItems[i].id);
                affectedNodes.push(...children);
            }

            // mark parent nodes if all children were selected
            for (let i = 0; i < parentIds.length; i++) {
                if (isAllChildrenChecked(bfsSearch(items, parentIds[i]), affectedNodes)) {
                    affectedNodes.push(parentIds[i]);
                }
            }
        } else {
            const allChildren = getAllChildren(nodeId);
            const parents = getAllParents(nodeId);

            if (selectedItems.includes(nodeId)) {
                // items to uncheck
                affectedNodes.push(...[...allChildren, ...parents]);
            } else {
                // items to check
                affectedNodes.push(...allChildren);
                for (let i = 0; i < parents.length; i++) {
                    if (isAllChildrenChecked(bfsSearch(items, parents[i]), affectedNodes)) {
                        affectedNodes.push(parents[i]);
                    }
                }
            }
        }

        if (
            event
                ? event.target.checked
                : selectedItems.findIndex((itemId) => itemId === nodeId) < 0
        ) {
            // check all affected
            setSelectedItems((prevSelectedNodes) => {
                const newChecks = affectedNodes.filter(
                    (nodeId) => prevSelectedNodes.findIndex((oldNodeId) => oldNodeId === nodeId) < 0
                );

                return [...prevSelectedNodes, ...newChecks];
            });
        } else {
            // uncheck all affected
            setSelectedItems((prevSelectedNodes) =>
                prevSelectedNodes.filter((id) => !affectedNodes.includes(id))
            );
        }

        setLastSelectedId(nodeId);
    };

    const handleExpandClick = (event) => {
        // prevent the click event from propagating to the checkbox
        event.stopPropagation();
    };

    const deselectSearchResults = () => {
        // Deselects visible search results. Without query deselects all items.
        if (filteredItems) {
            const filteredIds = filteredItems
                .map((item) => {
                    return getAllIds(item);
                })
                .flat(1);
            const remainingItems = selectedItems.filter((id) => filteredIds.indexOf(id) < 0);

            setSelectedItems(remainingItems);
        } else {
            setSelectedItems([]);
        }
    };

    const selectSearchResults = () => {
        // Selects visible search results. Without query selects all items.
        const toSelect = filteredItems ? filteredItems : items;
        const idBranches = toSelect.map((item) => {
            return getAllIds(item);
        });
        const selectedUnion = [...new Set([...selectedItems, ...idBranches.flat(1)])];

        setSelectedItems(selectedUnion);
    };

    const renderTree = (node) => {
        return (
            <TreeItem
                key={node.id}
                nodeId={node.id}
                onClick={handleExpandClick}
                label={
                    <Box className={classes.treeItemLabel}>
                        <Typography>{node.label}</Typography>
                        <Checkbox
                            checked={selectedItems.indexOf(node.id) !== -1}
                            disableRipple
                            onClick={(event) => handleNodeSelect(event, node.id)}
                        />
                    </Box>
                }>
                {node.children.map((node) => renderTree(node))}
            </TreeItem>
        );
    };

    return (
        <Grid container direction="column" spacing={3} className="picker-container">
            <Grid container item>
                {!!searchBarOptions && (
                    <Grid item xs={12}>
                        <SearchBar
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            label={searchBarOptions.label}
                            placeholder={searchBarOptions.placeholder}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TreeView
                        multiSelect
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        selected={selectedItems}
                        className="item-list">
                        {filteredItems.map((node) => renderTree(node))}
                    </TreeView>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid
                    container
                    direction={isSmUp ? "row" : "column"}
                    spacing={isSmUp ? 3 : 1}
                    justifyContent="flex-end"
                    alignItems={isSmUp ? "center" : "flex-end"}>
                    {!!searchQuery && (
                        <Fade in={!!searchQuery}>
                            <Grid item>
                                <Tooltip title="Only search results will be affected by Select/Deselect buttons">
                                    <HelpIcon />
                                </Tooltip>
                            </Grid>
                        </Fade>
                    )}
                    <Grid item>
                        <Button
                            variant="contained"
                            color={"primary"}
                            onClick={selectSearchResults}
                            disabled={selectedItems.length === totalNodeCount}
                            className={
                                searchQuery ? classes.selectVisibleButton : classes.selectAllButton
                            }>
                            {searchQuery ? "Select results" : "Select all"}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color={"primary"}
                            onClick={deselectSearchResults}
                            disabled={selectedItems.length === 0}
                            className={
                                searchQuery ? classes.selectVisibleButton : classes.selectAllButton
                            }>
                            {searchQuery ? "Deselect results" : "Deselect all"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
