import React, { useContext, useEffect, useState } from "react";
import { getUserConfiguration, createUserConfiguration, getProjects } from "api/api";
//
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ProjectAutoSuggest from "containers/components/autoSuggest/ProjectAutoSuggest";
import { ACCESS_LEVEL } from "containers/components/autoSuggest/ProjectAccessLevelAutoSuggest";
import { UserContext } from "App";
import TaskAutoSuggest from "containers/components/autoSuggest/TaskAutoSuggest";
import DayFilter from "containers/components/dayFilter/DayFilter";
import { guessLocation } from "../components/autoSuggest/LocationAutoSuggest";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";

import UserInfo from "../components/misc/UserInfo";
import Tooltip from "@material-ui/core/Tooltip";

export default function UserSettings(props) {
    const userContext = useContext(UserContext);
    const [userConfig, setUserConfig] = useState({ project: null, task: null, workingDays: null });
    const user = props.user;
    const [loading, setLoading] = useState(false);
    const [userLocation, setUserLocation] = useState();

    useEffect(() => {
        setLoading(true);
        getUserLocation();
        getUserConfiguration(true)
            .then((res) => {
                setUserConfig({
                    project: res.project,
                    task: res.task,
                    workingDays: res.workingDays,
                });
                setLoading(false);
            })
            .catch(() => {
                setUserConfig({ project: null, task: null, workingDays: null });
                setLoading(false);
            });
    }, []);

    function fetchProjects(q, pageSize, background) {
        return getProjects(
            q,
            userContext?.Permissions?.GlobalAdministration ? "" : ACCESS_LEVEL.USER,
            null,
            0,
            pageSize,
            null,
            null,
            background
        ).then((value) => value.content);
    }

    async function getUserLocation() {
        await guessLocation(null, userContext).then((loc) => {
            setUserLocation(loc);
        });
    }

    const handleDaysChange = (value) => {
        setUserConfig({ ...userConfig, workingDays: value });
    };
    const handleSubmit = () => {
        createUserConfiguration({
            projectId: userConfig.project?.Id,
            taskId: userConfig.task?.Id,
            workingDays: userConfig.workingDays,
        });
    };

    return (
        <>
            <h2 className="custom-title">Settings</h2>
            <Grid container spacing={2}>
                <Grid item container xs={12} md={12} spacing={2}>
                    <Grid item xs={12}>
                        <UserInfo user={user} />
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={12} spacing={2}>
                    <Grid item xs={12}>
                        <h3 className="subtitle-lightweight">Set default options</h3>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Tooltip title="Set default project" placement="top">
                            <div>
                                <ProjectAutoSuggest
                                    label="Default project"
                                    value={userConfig.project}
                                    fetchOptions={(q) => {
                                        return fetchProjects(q, 32, true);
                                    }}
                                    onChange={(project) => {
                                        setUserConfig({
                                            ...userConfig,
                                            project: project,
                                            task: null,
                                        });
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Tooltip title="Set default task" placement="top">
                            <div>
                                <TaskAutoSuggest
                                    label="Default task"
                                    project={userConfig.project}
                                    value={userConfig.task}
                                    onChange={(task) => {
                                        setUserConfig({ ...userConfig, task: task });
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>

                {!!userLocation && !!userConfig && (
                    <>
                        <Grid item container>
                            <DayFilter
                                days={
                                    userConfig?.workingDays
                                        ? userConfig.workingDays
                                        : userLocation?.workingDays
                                }
                                onDaysChange={(value) => {
                                    handleDaysChange(value);
                                }}
                            />
                        </Grid>
                        <Grid item container justifyContent="center">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    handleDaysChange(null);
                                    getUserLocation();
                                }}
                                startIcon={<SettingsBackupRestoreIcon />}>
                                {" "}
                                reset working days
                            </Button>
                        </Grid>
                    </>
                )}
                <Grid item container xs={12} justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
