import React from "react";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles/index";
import { DATE_FORMAT } from "../../../api/date";

const styles = (theme) => ({
    root: {
        display: "block",
    },
    input: {
        fontSize: "14px",
        width: "100%",
    },
});

class InternalDatePicker extends React.PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <KeyboardDatePicker
                className={classes.root}
                disabled={this.props.disabled}
                autoOk
                variant="inline"
                inputVariant="filled"
                minDate={this.props.minDate}
                minDateMessage={null}
                value={this.props.value}
                onChange={(date) => this.props.onChange(date)}
                onClear={() => this.props.onChange(null)}
                format={this.props.format || DATE_FORMAT}
                views={this.props.views || ["year", "month", "date"]}
                invalidDateMessage={null}
                label={this.props.label}
                InputProps={{
                    className: classes.input,
                }}
                PopoverProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                }}
                error={this.props.error}
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(InternalDatePicker);
