import React, { forwardRef } from "react";
import { TableVirtuoso } from "react-virtuoso";
//
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function mapHeaders(headers) {
    return headers.map((header) => ({
        label: header.name,
        dataKey: header.key,
        align: header.align,
    }));
}

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        borderCollapse: "separate",
        tableLayout: "fixed",
    },
    tableHeadCell: {
        backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#363636",
    },
    tableWrapperContent: {
        width: "100%",
        height: "calc(100vh - 80px)",
    },
    tableWrapperEmpty: {
        width: "100%",
        height: 150,
    },
}));

export default function ResultTableVirtualized({ headers, rows }) {
    const theme = useTheme();
    const classes = useStyles();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
    const VirtuosoTableComponents = {
        Scroller: forwardRef((props, ref) => (
            <TableContainer
                component={Paper}
                className={classes.tableContainer}
                {...props}
                ref={ref}
            />
        )),
        Table: (props) => <Table size={isMdUp ? "medium" : "small"} {...props} />,
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
        EmptyPlaceholder: () => (
            <>
                {rows?.length === 0 ? (
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" colSpan={headers.length}>
                                {rows === null && <b />}
                                {rows && rows.length === 0 && <b>No entries</b>}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody></TableBody>
                )}
            </>
        ),
    };

    function makeHeaders() {
        return (
            <TableRow>
                {mapHeaders(headers).map((column) => (
                    <TableCell
                        key={column.dataKey}
                        variant="head"
                        align={column.align || "right"}
                        className={classes.tableHeadCell}
                        style={{ width: column.width }}>
                        {column.label}
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    function makeRow(_index, row) {
        return (
            <>
                {headers.map((header) => {
                    const value = header.mapper ? header.mapper(row[header.key]) : row[header.key];

                    return (
                        <TableCell key={header.key} align={header.align || "right"}>
                            {value}
                        </TableCell>
                    );
                })}
            </>
        );
    }

    return (
        <Paper
            className={rows?.length > 0 ? classes.tableWrapperContent : classes.tableWrapperEmpty}>
            <TableVirtuoso
                data={rows || []}
                components={VirtuosoTableComponents}
                fixedHeaderContent={makeHeaders}
                itemContent={makeRow}
            />
        </Paper>
    );
}
