import React, { useEffect, useState } from "react";
import { updateLocation } from "api/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import DayFilter from "containers/components/dayFilter/DayFilter";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ControlledAutocomplete from "containers/components/form/ControlledAutocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
        overflowY: "visible",
    },
    content: {
        overflowY: "visible",
    },
}));

const schema = yup
    .object({
        weeklyHours: yup.number().required(),
        workingDays: yup.array(yup.number()).min(1).max(7).required(),
        hours: yup.array(yup.number()).length(8).required(),
    })
    .required();

export default function LocationHoursDialog({ location, open, handleClose }) {
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            weeklyHours: 40,
            workingDays: [1, 2, 3, 4, 5],
            hours: [0.5, 1, 2, 4, 5, 6, 7, 8],
        },
        resolver: yupResolver(schema),
    });
    const classes = useStyles();
    const hoursList = [...Array(22).keys()].map((i) => i / 2 + 0.5);

    useEffect(() => {
        if (location) {
            setValue(
                "hours",
                location.buttons.map((m) => m / 60)
            );
            setValue("weeklyHours", location.weekHours / 60);
            setValue("workingDays", location.workingDays);
        }
    }, [location]);

    const onSubmit = (data) => {
        updateLocation(location.id, {
            title: location.title,
            domain: location.domain,
            shortcut: location.shortcut,
            weekHours: data.weeklyHours * 60,
            workingDays: data.workingDays,
            buttons: data.hours.map((h) => h * 60),
        }).then(() => {
            handleSubmit();
            handleClose();
        });
    };

    const handleHoursChange = (newValue) => {
        return newValue.sort((a, b) => a - b);
    };

    const handleDaysChange = (newValue) => {
        setValue("workingDays", newValue);
    };

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.dialog,
            }}>
            <DialogTitle>Worklog hours - {location?.key}</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent className={classes.content}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                {...register("weeklyHours")}
                                id="weeklyHours"
                                label="Weekly hours"
                                variant="outlined"
                                fullWidth
                                error={!!errors?.weeklyHours}
                            />
                        </Grid>
                        <Grid item>
                            <DayFilter
                                days={getValues("workingDays")}
                                onDaysChange={(value) => {
                                    handleDaysChange(value);
                                }}
                            />
                            <FormHelperText>Please select min 1 value</FormHelperText>
                        </Grid>
                        <Grid item>
                            <ControlledAutocomplete
                                name="hours"
                                label="Hours in worklog"
                                options={hoursList}
                                multiple={true}
                                getOptionLabel={(option) => {
                                    return option + "h";
                                }}
                                renderOption={(option) => {
                                    return option + "h";
                                }}
                                handleChange={handleHoursChange}
                                control={control}
                                error={errors?.hours}
                                type="text"
                            />
                            <FormHelperText>Please select 8 values</FormHelperText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}>
                        submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
